@font-face {
	font-family: 'fontello';
	src: url('./fontello.eot?69233275');
	src: url('./fontello.eot?69233275#iefix') format('embedded-opentype'),
		url('./fontello.woff2?69233275') format('woff2'),
		url('./fontello.woff?69233275') format('woff'),
		url('./fontello.ttf?69233275') format('truetype'),
		url('./fontello.svg?69233275#fontello') format('svg');
	font-weight: normal;
	font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?69233275#fontello') format('svg');
  }
}
*/

[class^='icon-']:before,
[class*=' icon-']:before {
	font-family: 'fontello';
	font-style: normal;
	font-weight: normal;
	speak: none;

	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: 0.2em;
	text-align: center;
	/* opacity: .8; */

	/* For safety - reset parent styles, that can break glyph codes*/
	font-variant: normal;
	text-transform: none;

	/* fix buttons height, for twitter bootstrap */
	line-height: 1em;

	/* Animation center compensation - margins should be symmetric */
	/* remove if not needed */
	margin-left: 0.2em;

	/* you can be more comfortable with increased icons size */
	/* font-size: 120%; */

	/* Font smoothing. That was taken from TWBS */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	/* Uncomment for 3D effect */
	/* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-back:before {
	content: '\e800';
} /* '' */
.icon-close:before {
	content: '\e801';
} /* '' */
.icon-hamburger:before {
	content: '\e802';
} /* '' */
.icon-info:before {
	content: '\e803';
} /* '' */
.icon-key:before {
	content: '\e804';
} /* '' */
.icon-logout:before {
	content: '\e805';
} /* '' */
.icon-notification:before {
	content: '\e806';
} /* '' */
.icon-play:before {
	content: '\e807';
} /* '' */
.icon-piggy-bank:before {
	content: '\e808';
} /* '' */
.icon-profile:before {
	content: '\e809';
} /* '' */
.icon-user:before {
	content: '\e80a';
} /* '' */
